
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { customLogger } from '../common/Utils/Utils';


function PopupModal({ className,children, title, size = 'md', visible, handleClose, message, buttons, scrollable = false, Backdrop, closeButton=true }) {
  const [show] = useState(visible);
  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
      {children ?

        <Modal className={className} show={visible} onHide={handleClose} centered size={size} scrollable={scrollable} backdrop={Backdrop&& Backdrop}>
          <Modal.Header closeButton={closeButton}>
            <Modal.Title className='h6 text_master'>
              {title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body  >
            {children}
          </Modal.Body>
          {buttons && buttons.length
          &&
          <Modal.Footer>
            {buttons?.map(item =>
              <Button variant={item?.variant} size='sm' className='px-4' onClick={item?.onPress}>{item?.text}</Button>
            )}
          </Modal.Footer>
          }
        </Modal>
        :
        <Modal show={show} onHide={handleClose} centered size={size} scrollable={scrollable}>
          <Modal.Header closeButton={closeButton}>
            <Modal.Title className='h6 text_master'>
              {title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{message}</p>
          </Modal.Body>

          <Modal.Footer>
            {buttons?.map(item =>
              <Button variant={item?.variant} size='sm' className='px-4' onClick={item?.onPress}>{item?.text}</Button>
            )}
          </Modal.Footer>
        </Modal>
      }
    </>
  )
}

export default PopupModal