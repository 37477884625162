
import { Link, useNavigate } from "react-router-dom"
import Logo from "../../assets/images/Logo.png"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { FailedPopup, FormValidation, JSONStringify, clearSessionStorage, customLogger, removeSessionStorage, setSessionStorage } from "../../common/Utils/Utils"
import InputField from "../../Components/InputField/InputField"
import { ManageApis } from "../../common/APIS/ManageApis"
import { ContextAPI } from "../../common/Context/ContextApi"
import LoginRegLayout from "../UserLoginReg/LoginReg"
import PopupModal from "../../Components/PopupModal"
import Captcha from "../../Components/CaptchaScreen/Captcha"
import StaticScreen from "../staticScreen/StaticScreen"

const InitialValue = {
	username: '',
	password: ''
}
function Login() {

	const inputRef = useRef(null);
	const navigate = useNavigate()
	const { setUserData, settoken } = useContext(ContextAPI)
	const [Errors, setErrors] = useState(Object.keys(InitialValue).map(item => ({ name: item, message: '' })))
	const [loginForm, setloginForm] = useState(InitialValue);
	const [IsLoading, setIsLoading] = useState(false)
	const [captchaval, setCaptchaval] = useState('')
	const [showCaptcha, setShowCaptcha] = useState(false)


	const [termsModal, settermsModal] = useState(false)
	const [privacyModal, setprivacyModal] = useState(false)
	const [contactModal, setContactModal] = useState(false)
	const [contactDetails, setContactDetails] = useState('')
	useEffect(() => {
		getContactDetails()
		// clearSessionStorage()
	}, [])
	const getContactDetails = async () => {
		let path = global.getContact
		//debugger
		ManageApis('get', path, '', '')
			.then((res) => {
				if (res.response_data.status) {
					setContactDetails(res.response_data.data.docs)
					//console.log('data',res.response_data.data.docs);
				} else {
					setContactDetails('')
				}
			}
			)
			.catch((err) => {
				customLogger('Error on Contact Details', err)
			})

	}

	useEffect(() => {
		if (inputRef.current) {
			if (inputRef.current.value) {
				const inputLength = inputRef.current.value.length;
				inputRef.current.setSelectionRange(inputLength, inputLength);
			}
			inputRef.current.focus();
		}
	}, [])


	useEffect(() => {

		return () => {
			setloginForm(InitialValue)
			//loadCaptchaEnginge(6)
		}
	}, [])

	const validate = () => Errors.some(item => item?.message)

	const handleCaptcha = async () => {
		if (!validate()) {
			setShowCaptcha(true)
			// debugger
			// let validation = await fieldvalidation()
			// if(validation){
			// 	setShowCaptcha(true)
			// }
		} else return
	}
	// const fieldvalidation = () => {
	// 	if(!loginForm.username && !loginForm.password){

	// 		setErrors((pre)=>([...pre,{name:"username",message:"Please enter the username"}]))
	// 		setErrors((pre)=>([...pre,{name:"password",message:"Please enter the password"}]))

	// 		return false
	// 	}else if(!loginForm.username){
	// 		FailedPopup('')
	// 		setErrors([{name:'username', message:'Please enter the username'}])

	// 		return false
	// 	}else if(!loginForm.password){
	// 		FailedPopup('')
	// 		setErrors([{name:'password', message:'Please enter the password'}])
	// 		return false
	// 	}
	// }

	const handleLogin = async () => {
		// customLogger(loginForm.username.isValid && loginForm.password.isValid)
		// return
		['auth', 'userdetails'].map(item => removeSessionStorage(item))
		let countReattempt = 0;


		if (!validate()) {
			setIsLoading(true);

			const rqstBody = {
				username: loginForm.username,
				password: loginForm.password,
			};


			let path = global.loginPath
			let response = await ManageApis("post", path, rqstBody);
			// customLogger({ response })
			const { status, message, data } = response.response_data || {}
			if (status) {
				const { userDetails, token } = data
				setUserData({ username: userDetails.username, firstname: userDetails.first_name, token: token, mobile: userDetails.mobile })
				// signin();

				let KycStatus = ''
				// await getData('KYC-status').then(res =>
				// 	KycStatus = res
				// )
				// 	.catch(err => {
				// 		customLogger('error in fetching PAN status')
				// 	})
				// debugger
				// clearSessionStorage()
				settoken(token)
				await removeSessionStorage()
				let userBasicDetails = { ...userDetails } //setting user basic details
				delete userBasicDetails.id
				customLogger("userBasicDetails", userBasicDetails);

				const setAuth = async () => {
					try {
						const data = await setSessionStorage('auth', JSON.stringify(token));
						return data === true; // Return true if setting authentication succeeds
					} catch (error) {
						console.error('Error setting auth:', error);
						return false; // Return false if setting authentication fails
					}
				};

				const setUserdataLocal = async () => {
					try {
						const data = await setSessionStorage('userdetails', JSON.stringify(userBasicDetails));
						return data === true
						// Return true if setting user details succeeds
					} catch (error) {
						console.error('Error setting user details:', error);
						return false; // Return false if setting user details fails
					}
				};
				const handleCheck = async () => {
					try {
						// Execute the setAuth and setUserdataLocal functions in parallel
						const [authSuccess, userDetailsSuccess] = await Promise.all([setAuth(), setUserdataLocal()]);

						// Check if both operations were successful
						if (authSuccess && userDetailsSuccess) {
							setloginForm(InitialValue);
							setIsLoading(false);
							navigate('/', { replace: true });
						} else {
							// Handle the case where either authentication or setting user details failed
							console.error('Authentication or setting user details failed.');
							if (countReattempt < 2) {
								// Retry the operation
								countReattempt++;
								await handleCheck();
							} else {
								// Show a failure popup and navigate to login page
								FailedPopup('Something went wrong, please login again', async () => navigate('/login'));
							}
						}
					} catch (error) {
						// Handle any unexpected errors
						console.error('An error occurred:', error);
					}
				};
				handleCheck()




			} else {
				// alert('req failed')
				setIsLoading(false);
				customLogger(response);
				// setshowCaptcha(false)
				FailedPopup(response)
				setShowCaptcha(false)
				//  Alert.alert("Login Faild", response.message, [{ text: "OK" }]);
				// signin();
				// navigation.navigate("Drawer")
			}


			// navigation.navigate("Home")
		} else return
	};

	const handleErrors = (name, message) => {
		//debugger
		customLogger(name, message)
		if (name.includes('password')) {
			message == "Enter a strong Password" ?
				setErrors(Errors.map(item => item.name == name ? { name: name, message: "Password must be a minimum of 8 characters and include special characters, uppercase and lowercase letters, and numbers." } : item))
				: setErrors(Errors.map(item => item.name == name ? { name: name, message } : item))
		} else {
			setErrors(Errors.map(item => item.name == name ? { name: name, message } : item))
		}
	}

	const handlesubmit = () => {
		let requiredItems = forms.filter(item => item.required).map(item => !!item.required && item.name)
		let validationResult = FormValidation(loginForm, requiredItems)
		//debugger
		if (validationResult.validation) {
			handleLogin()
		} else {
			if (validationResult.failedItems.length) {
				let requiredItems = forms.map(item => ({ name: item.name, message: validationResult.failedItems.includes(item.name) ? 'Field is required' : '' }))
				setErrors(requiredItems)
				// if (!Errors.length) {
				// } else {
				// 	setErrors(prev => [...prev, ...requiredItems])
				// }


			}
		}
	}

	const handleChange = useCallback((value, name) => {
		customLogger(({ value, name }))
		setloginForm(prev => ({ ...prev, [name]: value }))
	}, [loginForm])
	const forms = [
		{ type: 'username', label: 'Username', placeholder: '', required: true, onchange: handleChange, name: 'username', disabled: false, value: loginForm.username, nonCaseRes: true, inputRef: inputRef },
		{ type: 'password', label: 'Password', placeholder: '', required: true, onchange: handleChange, name: 'password', disabled: false, value: loginForm.password },
	]

	useEffect(() => {
		//console.log({ Errors })
		//console.log(!Object.values(Errors).every((item) => (item == "")));
	}, [Errors])

	return (
		<>
			<LoginRegLayout loading={IsLoading}>
				<div className="d-flex h-100 p-3 p-md-4 overflow-auto scrollbar1">
					<div className="m-auto max_w_350x py-3 w-100">
						<div>
							<div className="text-center">
								<img src={Logo} className="mb-4 logo_prop" />
							</div>
							<h1 className="h3 text-center text_master fw_700">LOGIN</h1>
							{/* <p className="text-center text_dark1 pb-3 d-none d-sm-block">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							</p> */}
							{forms.map(item =>
								<InputField {...item} errorMessage={Errors?.find(itm => itm.name == item.name)?.message} seterrorMessage={handleErrors} />
							)}
							<div>
								<button
									type="submit"
									className="btn px_btn btn-master px-4 rounded w-100 fw_500"
									// onClick={() => setShowCaptcha(true)}
									disabled={!loginForm.password || !loginForm.username || !Errors.map((itm) => (itm.message)).every((it) => (it == ""))}
									onClick={handleCaptcha}
								>
									Login
								</button>
							</div>
							<div className="text-center py-3 forgot_links">
								<Link to={'/forgotusername'} className="text_master2_d1 text-decoration-none fw_500 me-2 border-end pe-2 cursor-pointer">Forgot Username!</Link>
								<Link to={'/forgotpassword'} className="text_master2_d1 text-decoration-none fw_500 cursor-pointer">Forgot Password!</Link>
							</div>
							<div className="text-center pb-3 forgot_links">
								<span className="px-2 text_master fw_500"> New user</span>
								<Link to={'/register'} className="text_master2_d1 text-decoration-none fw_500 ws_nowrp cursor-pointer">Register now</Link>
							</div>

						</div>
					</div>
				</div>
				<div className='position-absolute bottom-0 start-0 w-100'>
					<div className="font_11 bg-white py-2 px-3">
						<div className="align-items-center d-flex justify-content-center">
							<div className="d-flex loginFooter_links">
								<span className="px_hover_1 cursor-pointer" onClick={() => settermsModal(true)}>Terms and conditions</span>
								<span className="px-1">|</span>
								<span className="px_hover_1 cursor-pointer" onClick={() => setprivacyModal(true)}>Privacy Policy</span>
								<span className="px-1">|</span>
								<span className="px_hover_1 cursor-pointer" onClick={() => setContactModal(true)}>Contact Us</span>
							</div>
						</div>
					</div>
				</div>
				{!!showCaptcha &&
					<PopupModal className='captcheModal' title="Captcha Verification" size="sm" visible={showCaptcha} handleClose={() => setShowCaptcha(false)}>
						<div className="">
							<Captcha onSubmit={handleLogin} disabledButton={false} />
						</div>
					</PopupModal>
				}
				{termsModal &&
					<PopupModal title="Terms and Conditions" size="lg" visible={termsModal} handleClose={() => settermsModal(false)}>
						<StaticScreen title={'Terms'} />
					</PopupModal>
				}
				{privacyModal &&
					<PopupModal title="Privacy Policy" size="lg" visible={privacyModal} handleClose={() => setprivacyModal(false)}>
						<StaticScreen />
					</PopupModal>
				}
				{contactModal &&
					<PopupModal title="Contact Us" size="md" visible={contactModal} handleClose={() => setContactModal(false)}>
						<div>
							<p className='font_14 mb-2'><span className='w_75x d-inline-flex'>Address </span>: {contactDetails.address || ''}</p>
							{contactDetails.email &&
								<p className='font_14 mb-2'><span className='w_75x d-inline-flex'>Email Us </span>: <a href={`mailto: ${contactDetails.email}`} className='text_master2_d1 text-decoration-none fw_500'>{contactDetails.email || ''}</a></p>
							}
							{(contactDetails.mobile || contactDetails.phone) &&
								<p className='font_14 mb-2'><span className='w_75x d-inline-flex'>Call Us </span>:  <a href={`tel: ${contactDetails.mobile}`} className='text_master2_d1 text-decoration-none fw_500'>{contactDetails.mobile || ''}</a>
									{contactDetails.phone &&
										<a href={`tel: ${contactDetails.phone}`} className='text_master2_d1 text-decoration-none fw_500 ms-2'>{contactDetails.phone || ''}</a>
									}
								</p>
							}
						</div>
					</PopupModal>
				}
			</LoginRegLayout>


		</>

	)
}
export default Login

