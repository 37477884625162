import { BrowserRouter, Navigate, useNavigate } from "react-router-dom";
import { ContextAPI } from "./common/Context/ContextApi";
import Router from "./Router";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/font-awesome/css/all.min.css";
import "./assets/css/config.css";
import "./assets/css/common.css";
import "./assets/css/style.css";
import "@arco-design/web-react/dist/css/arco.css";
import enUS from '@arco-design/web-react/es/locale/en-US';
import { ConfigProvider } from "@arco-design/web-react";
import './config/constant/globalVariable'
import { useCallback, useEffect, useState } from "react";
import { FailedPopup, JSONParse, getSessionStorage, resetNavigation, setSessionStorage } from "./common/Utils/Utils";
import { ManageApis } from "./common/APIS/ManageApis";

function App() {
  const [UserData, setUserData] = useState()
  const [token, settoken] = useState(getSessionStorage('auth'))
  const [notificationsDetails, setnotificationsDetails] = useState({})
  const [HomeData, setHomeData] = useState({});
  const [kycModal, setkycModal] = useState(false)
  const [messageShown, setMessageShown] = useState(false) 
  const [Loader, setLoader] = useState(false)
  
  let authToken = getSessionStorage('auth')
  let authUserDetails = getSessionStorage('userdetails')

  useEffect(() => {
    
    window.addEventListener('storage', handleStorage)
    return (() => window.removeEventListener('storage', handleStorage))
  }, [])

  const handleStorage = (e) => {
    if (e.key == "auth"||e.key =='userdetails') {
    
      settoken((prev)=>(getSessionStorage("auth")))
      
      resetNavigation('/login')
    }
  }

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
        e.message === 'ResizeObserver loop limit exceeded') {
        e.stopImmediatePropagation();
        e.preventDefault()
        document.querySelector(`#webpack-dev-server-client-overlay`).style.display = 'none'
      }
    })
  }, [])
  

  useEffect(() => {
    if (authToken&&!getSessionStorage('userdetails')?.redirect_from) {
      console.log("testr------->",{ token }, authToken)
    
      getNotification()
      dashboardListing()
    }
   
  }, [authToken])


  const getNotification = useCallback((token = authToken) => {
    let path = global.getNotificationDetails
    ManageApis('get', path, '', token)
      .then((res) => {
        // //console.log(res)
        const { status, message, data } = res.response_data || {}
        if (status) {
          setnotificationsDetails(data)
        } else {
          FailedPopup(res)
        }
      })
      .catch((err) => {
        //console.log(err)
      })
  }, [authToken])



	
	const dashboardListing = useCallback(async () => {
		setLoader(true)
		let response = await ManageApis("get", global.dashboard, "", authToken);
		//console.log("test" + response);
		// debugger
		if (response.message == "Success") {
		  //console.log(response.response_data.data);
		  let maindata = response.response_data.data;
		  // if (maindata.no_of_notLinkedAccounts !== 0) {
		  //   setVisible(true)
		  // }
		  setHomeData(maindata);
		  setLoader(false)
		} else {
		  FailedPopup(response);
		  setLoader(false)
		}
	  },[authToken]);


  

  return (
    <ConfigProvider locale={enUS}>
      <ContextAPI.Provider value={{
        UserData, setUserData,
        token, settoken,
        notificationsDetails, setnotificationsDetails,
        getNotification,
        dashboardListing,
        kycModal, setkycModal,
        HomeData, setHomeData,
        messageShown, setMessageShown
      }}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </ContextAPI.Provider>
    </ConfigProvider>


  );
}

export default App;


