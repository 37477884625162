import React from 'react'

export default function KeyValue({item,textStyle="text-black",style=''}) {
  return (
        <div className={`col-12 col-md-6 col-lg-4 pb-0 pb-sm-2 ${style}`}>
            <div className="row">
                <div className="col-12 col-sm-6 font_13">{item.key}</div>
                <div className="col-12 col-sm-6 pt-1 pt-sm-0 mb-3 mb-sm-0">
                    <div className="d-flex">
                        <span className="pe-1 d-none d-sm-block">:</span>
                        <span className={` word_break fw_600 font_13 ${textStyle}`}>{item.value}</span>
                    </div>
                </div>
            </div>
        </div>
  )
}
