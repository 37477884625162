import Layout from "../../Components/Layout/Layout"
import InputField from "../../Components/InputField/InputField"
import BackBtn from "../../Components/BackBtn/BackBtn"
import PopupModal from "../../Components/PopupModal"
import { FailedPopup, downloadBase64PDF, formatDate, getMonthsRange } from "../../common/Utils/Utils"
import { useContext, useEffect, useState } from "react"
import { ContextAPI } from "../../common/Context/ContextApi"
import { useLocation } from "react-router-dom"
import { ManageApis } from "../../common/APIS/ManageApis"
import moment from "moment"
import NoData from "../../Components/NoData/NoData"


const dates = getMonthsRange()
function ViewPassBook() {
  const CurrentDate = new Date()
  const { state } = useLocation()
  const { chittal_id, branch_id, chit_id, chittal_no,chittal_name } = state || {}
  const { UserData, token } = useContext(ContextAPI)
  const [startDate, setStartDate] = useState(dates.startDate)
  const [endDate, setEndDate] = useState(dates.endDate);
  const [isLoading, setisLoading] = useState(false)
  const [data, setdata] = useState([])
  const [visible, setvisible] = useState(false)
  const [selectedColItems, setselectedColItems] = useState([])
  // handle startDate

  const handledateChange = (value) => {
    if (value?.length) {
      setStartDate(value[0])
      setEndDate(value[1])
    }
  }



  // handle view passbook when stat and end date changes

  useEffect(() => {
    if (startDate && endDate) {
      handleViewPassbook()
    }
  },
    [startDate, endDate],
  )

  // handle view passbook axios call
  const handleViewPassbook = async () => {
    setisLoading(true)
    const reqstBody = {
      chittal_id: chittal_id,
      page: 1,
      size: 10,
      limit: 10,
      fromDate: formatDate(startDate,'DD-MM-YYYY', 'YYYY-MM-DD'),
      toDate: formatDate(endDate,'DD-MM-YYYY','YYYY-MM-DD'),
      branch_id: branch_id
    }
    let response = await ManageApis("post", global.getPassbook, reqstBody, token)
    if (response.message == 'Success') {
      //console.log("res", response)
      setisLoading(false)
      const { status, data: { docs } } = response.response_data
      if (status) {
        setdata(docs)
        //console.log(docs);

      }
    }
    else {
      setisLoading(false)
      //console.log("res", response)
      FailedPopup(response)
    }

  }

  const formatLabel = (item) => {
    const itemVal = item.replaceAll('_', ' ')
    const words = itemVal.split(" ")
    return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ");
};

  const handleClickViewMore = (item) => {
    let formatedDueDate = "--"
    //debugger
    if(item.due_date == "--"){
     
    }else{
       formatedDueDate =  formatDate(item.due_date,'DD-MM-YYYY', 'DD-MM-YYYY')
       if(formatedDueDate == 'Invalid date'){
          const itemVal = item.due_date.replaceAll('_', ' ')
          const words = itemVal.split(" ")
          formatedDueDate = words.map((word) => formatDate(word[0],'DD-MM-YYYY', 'DD-MM-YYYY')+ " / " + formatDate(word[1],'DD-MM-YYYY', 'DD-MM-YYYY')).join(" ");
       }
    }
    let array = [
      { key: "Challan No", value: item.challan_no },
      { key: "Transaction Date", value: item.transaction_date },
      { key: "Installment No", value: item.installment_no },
      { key: "Due Date", value: formatedDueDate },
      { key: "Auction Dividend", value: item.auction_dividend },
      { key: "Date of Remittance", value: item.date_of_remittance },
      { key: "Default Interest", value: item.default_interest },
      { key: "Suspense Amount", value: item.suspense_amount },
      { key: "Net Challan Amount", value: item.net_challan_amount },
      { key: "Subscription Due", value: item.subscription_due },
      { key: "Subscription Amount", value: item.subscription_amount },
    ]
    setselectedColItems(array)
    setvisible(true)
  }

  const handlecloseModal = () => {
    setselectedColItems([])
    setvisible(false)
  }

  // handle export passbook to pdf 
  const handleExportPassbookToPdf = async () => {
    
    const splitArray = chit_id.split('/');
    const result = splitArray.slice(0, 2).join('/') + '';
    
    const reqstBody = {
      branchId: branch_id,
      chittyNo: result,
      chittalNo: chittal_no,
      fromDate: moment(startDate,'DD-MM-YYYY').format('YYYY-MM-DD'),
      toDate: moment(endDate,'DD-MM-YYYY').format('YYYY-MM-DD'),
      // chittalName: chittal_name,
    }

    setisLoading(true)
    let response = await ManageApis("post", global.exportPassbookToPdf, reqstBody, token)
    if (response.message == "Success") {
      setisLoading(false)
      //console.log("res", response)
      let fileName = `passbook-${reqstBody.fromDate}-${reqstBody.toDate}-${Date.now()}`
      let data_ = response.response_data.data
      downloadBase64PDF(data_, fileName)
 
      // // ToastAndroid.show("Downloaded successfully", ToastAndroid.LONG)
      // let file = new File([response.response_data], { type: "application/pdf;charset=utf-8" });

    }
    else {
      setisLoading(false)
      //console.log("res", response)
      FailedPopup(response)
    }
  }
  return (
    <Layout loading={isLoading}>
      <div className='pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1'>
        <div className="mb-3">
          <div className="mb-3">
            <div className='align-items-center d-flex justify-content-between flex-wrap'>
              <BackBtn title={'View Chit Passbook'} />

              <div className="col-12 col-sm-7 col-lg-4 col-xl-auto">
                <div className='d-flex gap-2 p-2 bg-white rounded mt-2'>

                  <InputField marginBottom label={'Choose Date'} 
                    onchange={handledateChange}
                    type={'date-range'} 
                    value={[startDate, endDate]} 
                    onClear={() => {
                      setStartDate("");
                      setEndDate("")}}
                  />

                </div>
              </div>
            </div>
          </div>
          <div className="p-3 bg-white br_10 shadow_sm mb-2">
            <div className="align-items-center row">
              <div className="col-12 col-md-4 col-lg-3 mb-3 mb-md-0">
                <span className="pe-1 fw_700">Chit No :</span>
                <span className="fw_500">{chit_id}</span>
              </div>
              <div className="col-12 col-md-4 col-lg-3 mb-3 mb-md-0">
                <span className="pe-1 fw_700">Chittal No :</span>
                <span className="fw_500">{chittal_no}</span>
              </div>
              <div className="col-12 col-md-4 col-lg-6 d-flex justify-content-md-end">
                {data?.length ?
                 <button className="btn btn-master flex-fill flex-md-grow-0 font_12 px_btn py-1 rounded" onClick={handleExportPassbookToPdf}>Export as PDF</button>
                :""}
              </div>
            </div>
          </div>
          <div className="p-3 bg-white br_10 shadow_sm ">
          {data?.length ?
            <div className="table_type02 table-responsive custom_scroll1">
              <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
                <thead className="bg_master text-white">
                  <tr>
                    <th className="fw_500" style={{ width: "60px" }}>Sl.No</th>
                    <th className="fw_500">Challan No</th>
                    <th className="fw_500">Date</th>
                    <th className="fw_500">Installment No</th>
                    <th className="fw_500 text-end">Amount</th>
                    <th className="fw_500" style={{ width: "90px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item,index) =>
                    <tr>
                      <td>{1+index}</td>
                      <td>{item.challan_no? item.challan_no : "-"}</td>
                      <td>{item.transaction_date}</td>
                      <td>{item.installment_no? item.installment_no : "-"}</td>
                      <td className="text-end">{item.net_challan_amount}</td>
                      <td>
                        <button className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100" onClick={() => handleClickViewMore(item)}>More</button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          :
          <NoData title={'No data to display'} />
          }
          </div>
          <div className="p-3 bg-white br_10 shadow_sm d-none">
            <div className="d-flex">
              <div className="m-auto">
                <div className="text-center d-grid py-5 text_gray7">
                  <i className="far fa-file-alt font_18"></i>
                  <p className="mb-0 mt-2">No Data Found.</p>
                  <p className="mb-0 mt-1 font_12">please From and To date</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopupModal title="Challan Details" size="lg" visible={visible} handleClose={handlecloseModal}>
        <div className="pb-1">
          <div className="row">
            {selectedColItems.map(item =>
              <div className="col-12 col-md-6 pb-0 pb-sm-2">
                <div className="row">
                  <div className="col-12 col-sm-6 text_gray7">{item.key}</div>
                  <div className="col-12 col-sm-6 pt-1 pt-sm-0 mb-3 mb-sm-0">
                    <div className="d-flex">
                      <span className="pe-1 d-none d-sm-block">:</span>
                      <span className="text-black word_break fw_500">{item.value}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </PopupModal>
    </Layout>
  )
}
export default ViewPassBook