import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Layout from '../../../Components/Layout/Layout'
import { NewspaperOutline } from 'react-ionicons'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import BackBtn from '../../../Components/BackBtn/BackBtn'
import { ContextAPI } from '../../../common/Context/ContextApi'
import { ManageApis } from '../../../common/APIS/ManageApis'
import { FailedPopup, customLogger, formatDate } from '../../../common/Utils/Utils'
import DataTable from '../../../Components/DataTable/DataTable'
import { Skeleton } from '@arco-design/web-react'

function ChitDetails() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const { chit_id, branch_id } = state || {}
    const { token } = useContext(ContextAPI)
    const [ChitDetails, setChitDetails] = useState({})
    const [ChittalDetails, setChittalDetails] = useState([])
    const [loading, setloading] = useState(false)
    const [currentPage, setcurrentPage] = useState(1)
    const [totolPages, settotolPages] = useState(0)

    useEffect(() => {
        handleGetChitDetails()
    }, [])


    // get chit details from backend
    const handleGetChitDetails = useCallback(async () => {
        //debugger
        setloading(true)
        const reqstBody = {
            chit_id: chit_id,
            branch_id: branch_id
        }
        let response = await ManageApis("post", global.getChitDetails, reqstBody, token)
        if (response.message == 'Success') {
            customLogger("res", response)
            setloading(false)
            // debugger
            if (response.response_data.status) {
                //debugger
                setChitDetails(response.response_data.data.chit_details)
                //settotolPages(response.response_data.data.total)
                settotolPages(response.response_data.data.chittal_details.length)
                //let chitTerminationDate =  moment(response.response_data.data.chit_details.end_date).format("YYYY-MM-DD")
                setChittalDetails(response.response_data.data.chittal_details)
            }
        } else {
            setloading(false)
            FailedPopup(response)
        }
    }, [])

    const manageProxy = (rec) => {
        //debugger
        if (ChitDetails?.due_date) {
            navigate(`/manageproxy`, { state: { chittal_id: rec._id, chittal: rec, chit_id, nextAuctionDay: ChitDetails?.due_date ? formatDate(ChitDetails?.due_date,'YYYY-MM-DD', 'Do MMM YYYY') : '' } })
        } else return FailedPopup("You are unable to access this menu because you have not specified a next auction value.")
    }

    const ChitDetailArray = useMemo(() => [
        { title: 'Chit Number', value: chit_id },
        { title: 'Branch Name', value: ChitDetails.branch_name },
        { title: 'Chit Start Date', value: formatDate(ChitDetails.start_date, 'YYYY-MM-DD', 'DD-MM-YYYY') },
        { title: 'Chit Termination Date', value: formatDate(ChitDetails.end_date, 'YYYY-MM-DD', 'DD-MM-YYYY') },
        { title: 'Chit Pattern', value: ChitDetails.chit_pattern },
        { title: 'Sala', value: ChitDetails.sala },
        { title: 'Chit Date', value: ChitDetails.auction_day },
        { title: 'Chit Frequency', value: ChitDetails.frequency },
        // { title: 'Current Installment', value: ChitDetails.current_installment },
       // { title: 'Next Auction Date', value: formatDate(ChitDetails?.due_date, 'YYYY-MM-DD','DD-MM-YYYY') },
    ], [ChitDetails])

    const status = useMemo(() => ['Non Prized', 'Prized Unpaid', 'Prized Paid'].map((item, idx) => ({ id: idx, value: item })))

    const TableColumn = [
        { title: 'Sl.No', dataIndex: 'slno', render: (e, v, idx) => <div>{`${idx + 1}`}</div> },
        { title: 'Chittal Number', dataIndex: 'chittal_no' },
        { title: 'Prized Status', render: (v, rec) => status.find(item => item.id == rec.prized_status)?.value },
        {
            title: '', dataIndex: 'action', render: (v, rec) => {
                let chittal_id = rec._id;
                const { chittal_no, chit_id, branch_id, chittal_name } = rec
                //debugger
                const proxyAvailbale = (rec.prized_status == 0 && rec.pending_installments.length == 0 && ChitDetails.temperory === false)

                return (loading ? <Skeleton text={{ rows: 1, width: 100 }} />
                    :
                    <div className="d-flex gap-2 justify-content-end flex-column flex-sm-row">
                        {proxyAvailbale ?
                            //<Link to={'/manage-proxy'} state={{ chittal_id: rec._id, chittal: rec, chit_id, nextAuctionDay: ChitDetails?.due_date ? formatDate(ChitDetails?.due_date,'YYYY-MM-DD', 'Do MMM YYYY') : '' }} className="btn px_btn btn-master-line px-4 rounded fw_500 font_12" >Manage Proxy</Link>
                            <button  
                            className="btn px_btn btn-master-line px-4 rounded fw_500 font_12 ws_nowrp" 
                            onClick={() => manageProxy(rec)}>Manage Proxy</button>
                            :
                            <button disabled className="btn px_btn btn-master-line px-4 rounded fw_500 font_12 ws_nowrp">Manage Proxy</button>
                        }
                        <Link to={'/chittaldetails'} state={{ chit_id, chittal_name, ChitDetails, branch_id, chittal_id, chittal_no }} className="btn px_btn btn-master px-4 rounded fw_500 font_12" >View And Pay</Link>
                    </div>
                )
            }
        },
    ]

    
    const handleChangeTable = useCallback(({ current }) => {
        handleGetChitDetails(current)
        setcurrentPage(current)
    }, [])
    return (
        <Layout loading={loading}>
            <div className='pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1'>
                <div className="mb-3">
                    <div className='align-items-center d-flex justify-content-between flex-wrap'>
                        <BackBtn title={'Chits Details'} />
                    </div>
                </div>
                <div className="bg-white px-3 pt-3 pb-2 br_10 shadow_sm mb-3">
                    <div className="row">
                        {ChitDetailArray.map(item =>
                            <div className="col-12 col-md-6 col-lg-4 pb-0 pb-sm-2">
                                <div className="row">
                                    <div className="col-12 col-sm-6 font_13">{item.title}</div>
                                    <div className="col-12 col-sm-6 pt-1 pt-sm-0 mb-3 mb-sm-0">
                                        <div className="d-flex">
                                            <span className="pe-1 d-none d-sm-block">:</span>
                                            <span className="text-black word_break fw_600 font_13">{item.value}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div>
                    <div className="mb-3">
                        <div className='d-flex pt-2'>
                            <NewspaperOutline height="23px" width="23px" color={''} className="text_master" />
                            <h3 className='fw_600 font_18 text_master ms-2 mb-0'>My Chittal List</h3>
                        </div>
                    </div>
                    <div className="p-3 bg-white br_10 shadow_sm">
                        <div className="arco_table1 table-responsive custom_scroll1">
                            <DataTable columnData={TableColumn} rowData={ChittalDetails} onChangeTable={handleChangeTable} total={totolPages} current={currentPage} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ChitDetails
