global.baseUrl="https://mobileapi.ksfe.com"
global.version = "1.1.6" //production 1.1.5 -> UAT
// global.baseUrl = "https://stagemobileapi.ksfeonline.com";
global.loginPath = "/auth/login";
global.logOut = "/auth/logout";
global.sendOtpverifyMobile = "/registration/sendotp";
global.verifyMobileNumber = "/registration/verifyMobileNumber";
global.reSendOtp = "/registration/reSendOtp";
global.checkUserNameAvailablility =
  "/registration/checkUsernameAvailability?username=";
global.userRegister = "/registration/register";
global.accountList = "/account/getExistingAccounts";
global.sendforApproval = "/account/sendAccountVerifyRequest";
global.changePassword = "/user/checkPassword";
global.updatePassword = "/auth/updatePassword";
global.changeEmail = "/user/changeEmailId";
global.sentValidateEmail = "/customer/sentValidateEmail";
global.reSentValidateEmail = "/customer/reSentValidateEmail";
global.VerifyEmail = "/customer/validateEmail";

// verify the mobile otp and send email otp
global.verifyChangeEmail = "/customer/verifyChangeEmail";
// verify email otp
global.verifyChangeEmailOtp = "/customer/verifyChangeEmailOtp";
// resent mobile otp
global.reSentChangeEmailIdOtp = "/customer/reSentChangeEmailIdOtp";
// resent email otp
global.reSentChangeEmail = "/customer/reSentChangeEmail";

global.sentChangeEmailOtp = "/customer/sentChangeEmailOtp";
global.changeMobileNumber = "/user/changeMobileNumber";
global.doNotDistrub = "/user/doNotDistrub";
// verify mobile number
global.sendMobileOTP = "/customer/sendMobileOTP";
global.reSendMobileOTP = "/customer/resendMobileOTP";
global.verifyMobileOTP = "/customer/verifyNewMobile";
// my Chit details
global.getPassbookLoanEligibilityAmount =
  "/chit/getPassbookLoanEligibilityAmount";
global.chitList = "/chit/getList";
global.getChitDetails = "/chit/getChitDetails";
global.getChittalDetails = "/chit/getChittalDetails";
global.createProxy = "/chit/addProxy";
global.cancelProxy = "/chit/cancelProxy";
global.validateproxy = "/chit/validateproxy";
global.getCustomerChitdetails = "/chit/getCustomerChitdetails";
global.expressUserInterest = "/chit/expressUserInterest";
global.exportPassbookToPdf = "/chit/passbook/exportToPdf";
global.getPassbook = "/chit/getPassbookDetails";
global.sendAadharVerificationOtp = "/kyc/sendpanotp";
global.verifyAadhar = "/kyc/verifypanotp";
global.getNewChit = "/chit/getNewChits";
global.listBranch = "/general/listBranch";
global.getFeedBackType = "/general/getFeedbackTypes";
global.sendFeedBack = "/general/setFeedback";

// payment
global.addToCart = "/cart/addToCart";
global.addUserChittyToCart = "/cart/addUserChittyToCart";
global.listMyChitsForBranch = "/chit/listMyChitsForBranch";
global.cartDetails = "/cart/getDetails";
global.paymentHistory = "/payment/getTransactionHistory";
global.createtransaction = "/payment/createtransaction";
global.removeCartItem = "/cart/removeItem";
global.gatewaylist = "/payment/gatewaylist";
global.processpgresponse = "/payment/processpgresponse";
global.getTransactionDetails = "/payment/getTransactionDetails";
// create mpin
global.createPin = "/auth/mpinCreate?device_id=";
global.changeMpin = "/auth/changeMpin";
global.addressDetails = "/user/getAddressDetails";

// customer
global.getProfileDetails = "/customer/getProfileDetails";
global.getAddressDetails = "/customer/getAddressDetails";
global.getCustomerProfile = "/customer/getCustomerProfile";
global.updateCommunicationAddress = "/customer/updateCommunicationAddress";
global.updatePresentAddress = "/customer/updatePresentAddress";
// account
global.getApprovedAccounts = "/account/getApprovedAccounts";
global.sendAccountVerifyRequest = "/account/sendAccountVerifyRequest";
global.getCustomerChitDetails = "/customer/getCustomerChitDetails";
global.getAccountsFromCore = "/account/getAccountsFromCore";
// kyc
global.updatepandetails = "/kyc/updatepandetails";
//Login with Mpin
global.loginWithMpin = "/auth/loginWithMpin";
global.sentMpinOtp = "/auth/sentMpinOtp";
global.verifyMpinOtp = "/auth/verifyMpinOtp";
global.resentMpinOtp = "/auth/resentMpinOtp";
global.resetMpin = "/auth/resetMpin?device_id=";

global.forgotUsername = "/auth/forgotUsername";
global.forgotpassword = "/auth/forgotpassword";
global.verifyUsernameOtp = "/auth/verifyUsernameOtp";
global.resentUsernameOtp = "/auth/resentUsernameOtp";
global.changeUsername = "/auth/changeForgotUsername";
global.verifyPasswordOtp = "/auth/verifyPasswordOtp";
global.resentPasswordOtp = "/auth/resentPasswordOtp";
global.changeForgotPassword = "/auth/changeForgotPassword";

global.dashboard = "/customer/dashboard";
global.listbranch = "/general/listbranch";
//settings
global.doNotDistrub = "/customer/doNotDistrub";

//request
global.getUserRequest = "/general/getUserRequest";
global.getRequestOwner = "/general/getRequestOwner";
global.listschemes = "/general/listschemes";
global.createRequest = "/general/sendUserRequest";
global.listchat = "/general/listchat";
global.savechat = "/general/savechat";
// no internet conection text
global.noInternetConnection = "No Internet Connection";
// notification api
global.getNotificationDetails = "/customer/getNotificationDetails";
global.getNotificationList = "/customer/getNotifications";
global.clearNotification = "/customer/clearUserNotification";

// District List
global.listdistricts = "/general/listdistricts";
//proxy
global.getProxyList = "/chit/getProxyList";
global.getProxyDisclaimer = "/chit/getProxyDisclaimer";
global.listAuctionDetails = "/chit/listAuctionDetails";
global.getappversion = "/general/getappversion";

//emi
global.fetchLoanSchemes = "/loan/fetchLoanSchemes";
global.getEmiDetails = "/loan/getEmiDetails";

global.staticcontent = "/general/staticcontent?key=";

// my loans
global.loanList = "/loan/getList";
global.getLoanDetails = "/loan/getInstallments";
global.getInstallmentsByNumber = "/loan/getInstallmentsByNumber";
global.getLoanPassbook = "/loan/getPassBookDetails";
global.exportLoanPassbook = "/loan/getPassBookPdf";
global.getCustomerLoandetails = "/loan/getCustomerLoanDetails";
global.quickPayLoans = "/loan/getInstallmentsByNumberForQuickPay"

global.getContact = "/general/getgettings?key=contact";

//Gold od
global.getGoldOdAccounts = "/goldod/getaccounts"
global.getGoldOdAccountDetails = "/goldod/getaccountDetails"
global.rejectGoldODAccount = "/goldod/rejectbankaccount"
global.acceptGoldODAccount = "/goldod/confirmbankaccount"
global.goldODRequestPayment = "/goldod/requestpayment"
global.goldODPassBook = "/goldod/getpassbookdetails"
global.goldODPassBookPDf = "/goldod/exportpassbookdetails"

global.isGoldODEnabled = false


global.googleTag = "GTM-WQB6CVFT"