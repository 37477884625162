import React from 'react'
import { FailedPopup } from '../../common/Utils/Utils'

const GeneralButton = ({ onClick, disabled, title,disabledMessage, type="btn-master" }) => {


    return (
        disabled? 
                <button 
                    className={`btn px_btn ${type} px-4 rounded fw_500 font_12 w-100 disabled_tone`} 
                    // disabled={disabled}
                    onClick={() => !!disabledMessage && FailedPopup(disabledMessage)}>
                        {title}
                </button>
              :
                <button 
                    className={`btn px_btn ${type} px-4 rounded fw_500 font_12 w-100`} 
                    onClick={onClick}>
                        {title}
                </button>

    )
}

export default GeneralButton