import React, { useContext, useEffect, useState } from "react";
import InputField from "../../../Components/InputField/InputField";
import { ContextAPI } from "../../../common/Context/ContextApi";
import { ManageApis } from "../../../common/APIS/ManageApis";
import { customLogger,FailedPopup, sanitizedText } from "../../../common/Utils/Utils";
import { GoldOdConstants } from "../../../constant";
import { Nav, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import KeyValue from "../../../Components/KeyValue/KeyValue";

export default function ODLiveSection({
  balanceAmt,
  setModalProps,
  getReqBodyAndPath,
  InputData,
  setInputData,
  AccountDetails,
  availedAmt
}) {
  const { token, setnotificationsDetails } = useContext(ContextAPI);
  const navigation = useNavigate();

  const { pending_withdrawal, pending_collection } = AccountDetails;
  const [error, setError] = useState({});
  const [Tabindex, setTabindex] = useState(0);
  const [Labels, setLabels] = useState({
    fieldLabel: !Tabindex
      ? GoldOdConstants.AmtWithdrawalFieldLabel
      : GoldOdConstants.AmtDueFieldLabel,
      amtLabel: !Tabindex
      ? GoldOdConstants.AmtWithdrawal
      : GoldOdConstants.AmtDue,
  });

  const fields = [
    {
      label: Labels.amtLabel,
      value: Tabindex ? Math.abs(availedAmt) : Math.abs(balanceAmt),
      disabled: true,
      className: "text-end text-grey",
    },
    {
      label: Labels.fieldLabel,
      value: InputData,
      name: "amount",
      maxLength: 8,
      required:true,
      placeholder: "Enter amount",
      className: "text-end",
      errorMessage: error.message,
    },
  ];

  useEffect(() => {
    setInputData("")
    setError({})
  }, [Tabindex])
  

  const handleErrors = (name, message) => {
    customLogger((name, message));
    setError({ name: name, message });
  };

  const onInputChange = (text) => {
    text = sanitizedText(text);
    setInputData(text);
    let stateObj = "";
    if (text < 0) {
      //   stateObj = `The minimum permissible amount is limit??`;
    } else if (Tabindex == 0 && parseInt(text) > Math.abs(balanceAmt)) {
      stateObj = `The maximum permissible amount is ${Math.abs(balanceAmt)}`;
    } else if(Tabindex == 1 && parseInt(text) > Math.abs(availedAmt)){
      // stateObj = `The maximum permissible amount is ${Math.abs(availedAmt)}`
    }
    setError({ message: stateObj });
  };

  const handleChangeTab = (val) => {
    setTabindex(val);
    if (val)
      setLabels({
        fieldLabel: GoldOdConstants.AmtDueFieldLabel,
        amtLabel: GoldOdConstants.AmtDue,
      });
    else
      setLabels({
        fieldLabel: GoldOdConstants.AmtWithdrawalFieldLabel,
        amtLabel: GoldOdConstants.AmtWithdrawal,
      });
  };

  const callFaliedPopup = (pending_amount) => {
    FailedPopup({
      message: `An amount of ₹${
        pending_amount
      } is pending. Please try again later.`,
    });
  };

  const handlePaymentRequest = async (type) => {
    try {
      if (Tabindex == 0 && pending_withdrawal) {
        callFaliedPopup(pending_withdrawal)
        return;
      }else if(Tabindex == 1 && pending_collection){
        callFaliedPopup(pending_collection)
        return;
      }
      const { path, reqBody } = getReqBodyAndPath(type);
      const response = await ManageApis("post", path, reqBody, token);
      const { response_data, status_code } = response;
      const { status, errors, message } = response_data;
      switch (status_code) {
        case 200:
          setInputData("")
          if(Tabindex == 1){
            setnotificationsDetails(prev => ({ ...prev, cart_count: prev.cart_count + 1 }))
          } 
          setModalProps({
            open: true,
            title: status ? `` : `Failure`,
            message: Tabindex == 1 && status ? `Item successfully added to cart, Do you want to add more items?`:message,
            props: Tabindex == 1 && status ?[
              {
                  text: 'Proceed to cart',
                  onPress: () => { navigation("/cart") },
                  style: 'cancel',
                  variant:'master',
              },
              { 
                  text: 'Yes', 
                  onPress: () => navigation("/goldod"),
                  variant:'master',
              },
          ] : []
          });
          break;
        default:
          if (message.includes('already added')) {
            setModalProps({
                open: true,
                title: "Failed!",
                message: response.message,
                props: [
                    
                    { 
                        style: 'cancel', 
                        text: 'Cancel', 
                        variant:'master-line', 
                        onPress: () => { setModalProps({open : false}) } 
                    },
                    {
                        text: 'Go to cart',
                        variant:'master',
                        onPress: () => { navigation("/cart") },
                    },
                ]
            })}
            else{
              setModalProps({
                open: true,
                title: `Failure`,
                message: `${message}`,
              });
            }
          break;
      }
    } catch (error) {
      console.log("error -- ", error);
    }
  };

  return (
    <>
      <div className="mb-3">
        <div>
          <Tab.Container defaultActiveKey="General">
            <div className="mb-2 mb-sm-3">
              <Nav
                className="gap-2 gap-sm-3 nav-tabs nav_tab_t3"
                activeKey={Tabindex}
              >
                <Nav.Item className="flex-fill flex-sm-grow-0">
                  <Nav.Link
                    eventKey="General"
                    onClick={() => handleChangeTab(0)}
                  >
                    Request Withdrawal
                  </Nav.Link>
                </Nav.Item>
                {
                   AccountDetails?.sanction_amount !== AccountDetails?.balance_outstanding &&
                  <Nav.Item className="flex-fill flex-sm-grow-0">
                  <Nav.Link
                    eventKey="Scheme"
                    onClick={() => handleChangeTab(1)}
                  >
                    Repayment
                  </Nav.Link>
                </Nav.Item>
                  }
              </Nav>
            </div>
            <div></div>
          </Tab.Container>
        </div>
      </div>
      <div className="bg-white px-3 pt-3 pb-2 br_10 shadow_sm mb-3">
        {(Tabindex == 0 && pending_withdrawal) ?
        <div className="mb-3">
        <KeyValue item={{key:"Status",value:`A Withdrawal of Rs.${
          pending_withdrawal  
      } /- is under processing`}} textStyle='text-danger' style="col-lg-7"/>
      </div>
      :
      <></>
      }
        <div className="row mb-0">
          {fields.map((field, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4 pt-1 pt-sm-0">
              <InputField
                label={field.label}
                disabled={field.disabled}
                className={field.className}
                type={field.type || "number"}
                name={field?.name}
                required={field?.required}
                value={field.value}
                maxLen={field.maxLength}
                errorMessage={field.errorMessage}
                seterrorMessage={handleErrors}
                placeholder={field.placeholder}
                onchange={(text) => onInputChange(text)}
              />
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-end mb-2">
          <div>
            <button
              className="btn px_btn btn-master px-4 rounded fw_500 font_12"
              onClick={() =>
                handlePaymentRequest(Tabindex ? "pay" : "request")
              }
              disabled={error.message || InputData == 0  }
            >
              <span className="ws_nowrp">{"Submit"}</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
