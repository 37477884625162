
import { NewspaperOutline } from "react-ionicons"
import Layout from "../../Components/Layout/Layout"
import { Nav, Tab } from "react-bootstrap"
import PopupModal from "../../Components/PopupModal"
import { useCallback, useContext, useEffect, useState } from "react"
import { FailedPopup, customLogger, firstCamelCase, successPopup } from "../../common/Utils/Utils"
import { Radio, Button, Space, Typography, Spin } from '@arco-design/web-react';
import { ContextAPI } from "../../common/Context/ContextApi"
import BranchListDropdown from '../../Components/BranchList/BranchList'
import { ManageApis } from "../../common/APIS/ManageApis"
import NoData from "../../Components/NoData/NoData"
import BackBtn from "../../Components/BackBtn/BackBtn"


const status = [
  { id: 0, value: 'not_linked' },
  { id: 1, value: 'requested' },
  { id: 2, value: 'approved' },
  { id: 3, value: 'rejected' },
]

function MyAccount() {

  const [visible, setVisible] = useState(false)


  const { token,dashboardListing } = useContext(ContextAPI)
  const [vissible, setVissible] = useState(false);
  const [infomodal, setInfomodal] = useState(false);
  const [isLinkedModal, setIsLinkedModal] = useState(false);
  const [SelectedList, setSelectedList] = useState([]);
  const [notSelectedList, setnotSelectedList] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [loading, setloading] = useState(false)
  const [reasons, setreasons] = useState([])
  const [selectedAccountId, setselectedAccountId] = useState('')
  const [userChitsDetails, setuserChitsDetails] = useState([])
  const [userLoanDetails, setuserLoanDetails] = useState([])
  const [userGoldodDetails, setuserGoldodDetails] = useState([])
  const [searchText, setSearchText] = useState('')
  const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
 MyAccountList(searchText)
  }, [searchText])


  useEffect(() => {
    MyAccountList()
    //console.log("cleaning........................");
    setSelectedList([])
    setAccountData([])
    setnotSelectedList([])
    setSearchText('')
  }, [])

  useEffect(() => {
    //console.log({ SelectedList })
    //console.log({ notSelectedList })
  }, [SelectedList, notSelectedList])


  const handleChangeForSearch = useCallback((param) => {
    //console.log("oiiiiii---------------", text)
    setSearchText(param)
  }, [searchText])

  // fetch accounts list
  const MyAccountList = async (searchText) => {
   //debugger
    setSelectedList([])
    setloading(true)
    // let token = UserData.token
    //console.log(token)
    let body = ''
    if (searchText) {
      body = {
        "search": searchText
      }
    }
    // return
    await ManageApis("post", global.accountList, body, token)
      .then((res) => {
        //console.log('resp of account list', res)
        setloading(false)
        if (res.response_data.status) {
          if (res.response_data.data.docs) {
            let array = res.response_data.data.docs.map(item => ({ ...item, isChecked: true }))
            let tempListForSelecteditems = []
            array.map(item => { item.status === 0 && tempListForSelecteditems.push(item._id) })
            //console.log({ tempListForSelecteditems })
            setSelectedList(tempListForSelecteditems)
            //console.log("arrayaas", { array })
            setAccountData(array)
            //console.log("array:", array);
            // setSelectedList(array.map(item => item._id))
          } if (res.response_data.data.reasons) {
            setreasons(res.response_data.data.reasons)
          }

        }
      }).catch((err) => {
        setloading(false)
        //console.log('error in fetching account list', err)
      })


  }

  // handle send approval btn press
  const handleAprovalSend = async () => {
    setloading(true)
    const reqstBody = {
      my_accounts: SelectedList,
      not_my_accounts: notSelectedList
    }
    //console.log({ reqstBody })
    // debugger
    // const { token } = UserData
    await ManageApis("post", global.sendforApproval, reqstBody, token)

      .then(res => {
        //console.log(res)
        const { status, message, data } = res.response_data
        if (status) {
          setloading(false)
          successPopup(message)
          MyAccountList()
          dashboardListing()
          setVissible(false)
          window.location.reload(false)
          // showToast('success', status, message)
        } else {
          setloading(false)
          FailedPopup(res)
          setVissible(false)
          // showToast('error', status, message)
        }
      })
      .catch((err) => {
        //console.log(err)
        setloading(false)
        FailedPopup("Something went wrong, please try later")
        setVissible(false)
      })
  };

  // handle check box value
  const handelcheck = useCallback((itm) => {
    //console.log(itm._id)
    setselectedAccountId(itm._id)
    // debugger
    if (itm.isChecked) { //checking the card item checked or not
      setIsLinkedModal(true) // if its true then the modal will open
    } else {
      setAccountData(accountData.map(item => item._id == itm._id ? ({ ...item, isChecked: !item.isChecked }) : item))       // if its false then the checked will uncheck 
      setSelectedList(prev => [...prev, itm._id]) // adding to selected list
      setnotSelectedList(notSelectedList.filter(item => item.account_id !== itm._id)) //filterout the item from not selected list
    }

    // handleInfo()
    // setAccountData(accountData.map(item => item._id == itm._id ? { ...item, isChecked: !item.isChecked } : item))
  })



  const renderItem = () => {

    return (
      accountData?.map(itm =>
        <tr key={itm._id}>
        
          <td >
            {itm.status === 0 ?
              <input 
                type="checkbox" 
                value={itm.isChecked} 
                checked={itm.isChecked}
                className="form-check-input font_16" 
                onChange={() => handelcheck(itm)} 
              />
              :
              <input 
                type="checkbox" 
                checked
                disabled
                className="form-check-input font_16" 
              />
              
            }
            
          </td>
          <td>{itm.account_holder_name}</td>
          <td>{itm.customer_id}</td>
          <td>{itm.branch_name}</td>
          <td>
            {(itm.status === 3 && itm.remarks) &&
              itm.remarks
            }
          </td>
          <td>
            {(itm.status === 0) &&
              <span className="badge badge_gray_soft font_12 fw_500 rounded-pill text-capitalize">{status.find(item => item.id == itm.status).value.replace('_', ' ')}</span>
            }
            {(itm.status === 1) &&
              <span className="badge badge_warning_soft font_12 fw_500 rounded-pill text-capitalize">{status.find(item => item.id == itm.status).value.replace('_', ' ')}</span>
            }
            {(itm.status === 2) &&
              <span className="badge badge_success_soft font_12 fw_500 rounded-pill text-capitalize">{status.find(item => item.id == itm.status).value.replace('_', ' ')}</span>
            }
            {(itm.status === 3) &&
              <span className="badge badge_danger_soft font_12 fw_500 rounded-pill text-capitalize">{status.find(item => item.id == itm.status).value.replace('_', ' ')}</span>
            }
            {/* <span className="badge badge_warning_soft font_12 fw_500 rounded-pill">{status.find(item => item.id == itm.status).value.replace('_', ' ')}</span> */}
          </td>
          <td>
            <button className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100 text-nowrap" onClick={() => handleInfo(itm)} >More</button>
          </td>
        </tr>
      )
    );
  };



  //handle info render
  const handleInfo = (data) => {
    setInfomodal(true);
    getUserChitDetails(data._id, data.status, data.customer_id)
  };

  const getUserChitDetails = useCallback((id, status, customer_id) => {
    setuserChitsDetails([])
    setuserLoanDetails([])
    setuserGoldodDetails([])
    setloading(true)
    let path, body

    if (status == 2) {
      body = { account_id: id }
      path = global.getCustomerChitDetails
    } else {
      body = { customer_id: customer_id }
      path = global.getAccountsFromCore
    }
    // body = { customer_id: customer_id }
    // path = getAccountsFromCore
    // const { token } = UserData
    ManageApis('post', path, body, token)
      .then(res => {
        setloading(false)
        //console.log('CustomerChitDetails', res)
        const { status } = res.response_data
        //console.log("resp data", res.response_data)

        if (status) {
          if (res.response_data.data.chits.length) {
            setuserChitsDetails(res.response_data.data.chits)
          }
          if (res.response_data.data.loans.length) {
            setuserLoanDetails(res.response_data.data.loans)
          }
          if (res.response_data.data.goldod.length) {
            setuserGoldodDetails(res.response_data.data.goldod)
          }
        }
      })
      .catch((err) => {
        //console.log('error in CustomerChitDetails', err)
        setisLoading(false)
      })
  }, [])

  // selecting reason from modal when click on the checkbox(untick)
  const handleSelectReason = useCallback((item) => {
    //debugger
    setAccountData(accountData.map(itm => itm._id == selectedAccountId ? { ...itm, isChecked: !itm.isChecked } : itm)) //updating the ischecked value for showing the item selected or not
    setSelectedList(SelectedList.filter(item => item != selectedAccountId)) //removing the unticking item from selected list 
    if (item.reason.includes('Not mine')) {
      setnotSelectedList(prev => [...prev, { account_id: selectedAccountId, reason_id: item._id }]) // adding to not my account list
    }
    setIsLinkedModal(false) // hiding the modal
  }, [selectedAccountId])
   //console.log(notSelectedList)


  const handleClearBranch = () => { 
    MyAccountList(); }

  const handleModal = () => {
    setVisible('true')
    // customLogger(("te",visible));
  }
 


  return (
  <Layout loading={loading}>
    <div className='pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1'>	
      <div className="mb-3">
        <div className='align-items-start align-items-sm-center d-flex flex-column flex-sm-row justify-content-between flex-wrap'>
          <BackBtn title={'My Account'} />
         
            {/* <div className="bg-white col-12 col-lg-4 col-md-5 col-sm-6 col-xl-3 d-flex gap-2 mt-3 mt-sm-0 p-2 rounded">
              <BranchListDropdown
                  isUserBranch
                  selectedItem={handleChangeForSearch}
                  // label={'Branch'}
                  onChange={handleChangeForSearch}
                  onClear={handleClearBranch}
                  selectedItemString={(param) => setSearchText(param)}
                  value={searchText}
              />
            </div> */}
        </div>
      </div>
      <div className="mb-3">
        <div className="p-3 bg-white br_10 shadow_sm ">
          
          {
            !accountData.length ?
              <NoData />
            :
            <>
            {!!(SelectedList?.length || notSelectedList?.length) &&
              <div className="d-flex justify-content-end mb-3">
                <button className="btn btn-master px-4 font_14"
                onClick={() => setVissible(true)}
                >Send for Approval</button>
            </div>
            }
              
              <div className="table_type02 table-responsive custom_scroll1">
                <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
                  <thead className="bg_master text-white">
                    <tr>
                      <th className="fw_500 " style={{ width: "40px" }}></th>
                      <th className="fw_500">Customer Name</th>
                      <th className="fw_500">Id No</th>
                      <th className="fw_500">Branch Name</th>
                      <th className="fw_500">Remarks</th>
                      <th className="fw_500">Status</th>
                      <th className="fw_500" style={{ width: "90px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      renderItem()
                    }

                  </tbody>
                </table>
              </div>
            </>
          }
          
        </div>
      </div>
    </div>


    {isLinkedModal &&
        <PopupModal title="Reason" size="md" visible={isLinkedModal}  Backdrop={'static'}
        handleClose={() => setIsLinkedModal(false)}
        >
          <div>
            <div className="w-100">
              <Radio.Group name='card-radio-group' className={"w-100 d-flex justify-content-between"}>
                  {reasons.map((item) => {
                    return (
                      <Radio key={item} value={item} onClick={() => handleSelectReason(item)}>
                        {({ checked }) => {
                          return (
                            <Space
                              align='start'
                              className={`custom-radio-card w-100 br_5 border  ${checked ? 'custom-radio-card-checked' : ''}`}
                            >
                              <div className='custom-radio-card-mask d-inline-flex align-items-center justify-content-center rounded-circle w_14x h_14x border'>
                                <div className='custom-radio-card-mask-dot rounded-circle'></div>
                              </div>
                              <div>
                                <div className='custom-radio-card-title font_14 font_400 text-black'>{item.reason}</div>
                              </div>
                            </Space>
                          );
                        }}
                      </Radio>
                    );
                  })}
              </Radio.Group>
              {/* <div className="mt-3 d-flex justify-content-end">
                <button className="btn btn-master px-4 font_14 py-2 font_400">Submit</button>
              </div> */}
            </div>
          </div>
      </PopupModal>
    }


      {vissible &&
        <PopupModal title="Account Approval Confirmation" size="md" visible={vissible}  Backdrop={'static'}
        handleClose={() => setVissible(false)}
        >
          <div>
            {!!SelectedList.length &&
            <div>
              <p>Following customer accounts that are selected will be considered as your accounts and send to the Branch Manager for approval.</p>
            </div>
            }
            <div className="w-100">
              <div>
                <table className="table table-bordered">
                  <tbody>
                    
                    {SelectedList.map((item,index) => (
                      <tr>
                        <td>
                          {index+1}
                        </td>
                        <td>
                          <span>
                            {`${accountData?.find(itm => itm._id == item)?.branch_name} (${accountData?.find(itm => itm._id == item)?.customer_id})`}
                          </span>
                        </td> 
                      </tr>
                    ))}
                   
                  </tbody>
                </table>
              
              </div>
              <div className="mt-3 d-flex justify-content-end">
                <button className="btn btn-master px-4 font_14 py-2 font_400"
                  //disabled
                  disabled={!SelectedList || !notSelectedList} 
                  onClick={handleAprovalSend} 
                >Submit</button>
              </div>
            </div>
          </div>
      </PopupModal>
    }


    {infomodal &&
        <PopupModal title="Details" size="md" visible={infomodal} 
        handleClose={() => setInfomodal(false)}
        >
          <div>
            <Tab.Container defaultActiveKey="Chitty">
                <div className="mb-2 mb-sm-3">
                  <Nav className="gap-2 gap-sm-3 nav-tabs nav_tab_t3">
                    <Nav.Item className="">
                      <Nav.Link eventKey="Chitty">Chitty</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Loan">Loan</Nav.Link>
                    </Nav.Item>
                    {global.isGoldODEnabled &&
                      <Nav.Item>
                        <Nav.Link eventKey="GoldOD">Gold OD</Nav.Link>
                      </Nav.Item>
                    }
                  </Nav>
                </div>
                <div>
                  <Tab.Content>
                    <Tab.Pane eventKey="Chitty">
                      <div className="popUpmyAccount overflow-auto custom_scroll1">
            
                        {userChitsDetails.length > 0 ? userChitsDetails.map(item =>
                          <div className="border-bottom mx-0 pt-2 row">
                            <div className="col-12 col-sm-6 pe-sm-2 px-0">
                              <h6 className="font_11 font_400">Branch Name</h6>
                              <h1 className="font_15 font_500">{item.branchName}</h1>
                            </div>
                            <div className="col-12 col-sm-6 ps-sm-2 px-0">
                              <h1 className="font_11 font_400">Chit No</h1>
                              <h1 className="font_15 font_500">{`${item.chittyNo} (${item.chittalNo})`}</h1>
                            </div>
                          </div>
                          
                        ) :
                        <div className="bg_gray2 px-2 py-4 rounded text-center">No Chitty found</div>
                        }
                      
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Loan">
                      <div className="popUpmyAccount overflow-auto custom_scroll1">
                        
                        {userLoanDetails.length > 0 ? userLoanDetails.map(item =>
                          <div className="border-bottom mx-0 pt-2 row">
                            <div className="col-12 col-sm-4 pe-sm-2 px-0">
                              <h6 className="font_11 font_400">Branch</h6>
                              <h1 className="font_15 font_500">{item.branchName}</h1>
                            </div>
                            <div className="col-12 col-sm-4 pe-sm-2 ps-sm-2 px-0">
                              <h1 className="font_11 font_400">Account No</h1>
                              <h1 className="font_15 font_500">{item?.loanAccountNo || item?.accountNo }</h1>
                            </div>
                            {item.loanStatus ?  
                            <div className="col-12 col-sm-4 ps-sm-2 px-0">
                              <h1 className="font_11 font_400">Status</h1>
                              <h1 className="font_15 font_500">{item.loanStatus}</h1>
                            </div>
                            :
                            <div className="col-12 col-sm-4 ps-sm-2 px-0">
                              <h1 className="font_11 font_400">Description</h1>
                              <h1 className="font_15 font_500">{item.loanDescription}</h1>
                            </div>
                            }
                          </div>
                          
                        ) :
                        <div className="bg_gray2 px-2 py-4 rounded text-center">No Loan found</div>
                        }
                        
                        
                       
                      </div>
                    </Tab.Pane>
                    {global.isGoldODEnabled &&
                    <Tab.Pane eventKey="GoldOD">
                      <div className="popUpmyAccount overflow-auto custom_scroll1">
                        
                        {userGoldodDetails.length > 0 ? userGoldodDetails.map(item =>
                          <div className="border-bottom mx-0 pt-2 row">
                            <div className="col-12 col-sm-4 pe-sm-2 px-0">
                              <h6 className="font_11 font_400">Branch</h6>
                              <h1 className="font_15 font_500">{item.branchName}</h1>
                            </div>
                            <div className="col-12 col-sm-4 pe-sm-2 ps-sm-2 px-0">
                              <h1 className="font_11 font_400">Account No</h1>
                              <h1 className="font_15 font_500">{item?.loanAccountNo}</h1>
                            </div>
                            <div className="col-12 col-sm-4 ps-sm-2 px-0">
                              <h1 className="font_11 font_400">Sanctioned Amount</h1>
                              <h1 className="font_15 font_500">{item.sanctionAmount}</h1>
                            </div>
                          </div>
                          
                        ) :
                        <div className="bg_gray2 px-2 py-4 rounded text-center">No Gold OD found</div>
                        }
                        
                        
                       
                      </div>
                    </Tab.Pane>
                       }
                  </Tab.Content>
                </div>
            </Tab.Container>
          </div>
      </PopupModal>
    }
  </Layout>

  )
  
}

export default  MyAccount